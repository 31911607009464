var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.contactPersons),function(pdata,index){return [_c('v-layout',{key:'contact-person-' + index},[_c('v-flex',[_c('v-select',{attrs:{"items":_vm.salutationList,"dense":"","filled":"","rules":[
                      _vm.validateRules.required(pdata.title, 'salutation') ],"item-text":"text","item-value":"value","item-color":"cyan","label":"Salutation","solo":"","flat":"","color":"cyan"},model:{value:(pdata.title),callback:function ($$v) {_vm.$set(pdata, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.title"}})],1),_c('v-flex',{attrs:{"md":_vm.isDialog ? 4 : 2,"sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.required(pdata.first_name, 'first name'),
                      _vm.validateRules.minLength(
                        pdata.first_name,
                        'first name',
                        2
                      ),
                      _vm.validateRules.maxLength(
                        pdata.first_name,
                        'first name',
                        100
                      ) ],"dense":"","filled":"","label":"First Name","solo":"","flat":"","color":"cyan"},model:{value:(pdata.first_name),callback:function ($$v) {_vm.$set(pdata, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.first_name"}})],1),_c('v-flex',{attrs:{"md":_vm.isDialog ? 4 : 2,"sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.minLength(
                        pdata.last_name,
                        'last name',
                        2
                      ),
                      _vm.validateRules.maxLength(
                        pdata.last_name,
                        'last name',
                        100
                      ) ],"dense":"","filled":"","label":"Last Name","solo":"","flat":"","color":"cyan"},model:{value:(pdata.last_name),callback:function ($$v) {_vm.$set(pdata, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.last_name"}})],1),_c('v-flex',{attrs:{"md":_vm.isDialog ? 4 : 2,"sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.required(
                        pdata.display_name,
                        'display name'
                      ),
                      _vm.validateRules.minLength(
                        pdata.display_name,
                        'display name',
                        2
                      ),
                      _vm.validateRules.maxLength(
                        pdata.display_name,
                        'display name',
                        100
                      ) ],"dense":"","filled":"","label":"Display Name","solo":"","flat":"","color":"cyan"},model:{value:(pdata.display_name),callback:function ($$v) {_vm.$set(pdata, "display_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.display_name"}})],1),_c('v-flex',{attrs:{"md":_vm.isDialog ? 4 : 2,"sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currentPhoneMask),expression:"currentPhoneMask"}],staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.required(pdata.primary_phone, 'mobile'),
                      _vm.validateRules.minLength(
                        pdata.primary_phone,
                        'mobile',
                        6
                      ),
                      _vm.validateRules.maxLength(
                        pdata.primary_phone,
                        'mobile',
                        15
                      ) ],"dense":"","filled":"","label":"Mobile","solo":"","flat":"","color":"cyan"},model:{value:(pdata.primary_phone),callback:function ($$v) {_vm.$set(pdata, "primary_phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.primary_phone"}})],1),_c('v-flex',{attrs:{"md":_vm.isDialog ? 4 : 2,"sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.required(pdata.primary_email, 'email'),
                      _vm.validateRules.validEmail(pdata.primary_email, 'email'),
                      _vm.validateRules.minLength(
                        pdata.primary_email,
                        'email',
                        2
                      ),
                      _vm.validateRules.maxLength(
                        pdata.primary_email,
                        'email',
                        100
                      ) ],"dense":"","filled":"","label":"Email","solo":"","flat":"","color":"cyan"},model:{value:(pdata.primary_email),callback:function ($$v) {_vm.$set(pdata, "primary_email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pdata.primary_email"}})],1),(!_vm.isDialog)?[_c('v-flex',{attrs:{"md":"1","sm":"12"}},[(index > 0)?[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red lighten-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.removeContactPerson(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus")])],1)]:[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"cyan","dark":"","fab":"","small":""},on:{"click":_vm.pushContactPerson}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")])],1)]],2)]:_vm._e()],2)]})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }